<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        style="height: 88vh;"
      >
        <iframe
          :src="$store.state.url.replace('api/', '') + 'infostart/'"
          frameborder="0"
          style="border-radius: 5px; height: 100%; width: 100%"
        />

        <!--welcome></welcome-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // import welcome from './components/welcome'

  export default {
    name: 'DashboardDashboard',
    components: {
      // welcome,
    },
    data () {
      return {
        itemWikiS: '',

      }
    },

    computed: {
      totalSales () {
        return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
      },
    },

    methods: {
      searchwiki () {
        window.location.href = 'http://it/projects/sysadmin/search?utf8=✓&wiki_pages=1&q=' + this.itemWikiS
        console.log('sss')
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
    },
  }
</script>
